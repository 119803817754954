<template>
  <transition name="modal-fade">
    <div ref="modal" class="modal-backdrop" @click="clickOutside">
      <div class="modal" role="dialog">
        <div class="btn-close" @click="close">
          <md-icon>close</md-icon>
        </div>

        <h4>Изменить предмет магазина</h4>

        <ValidationObserver
          v-if="selectedItem"
          ref="shopItemForm"
          class="md-layout md-gutter"
          tag="div"
        >
          <ValidationProvider
            v-slot="{ errors, reset }"
            class="md-layout-item md-large-size-40"
            mode="passive"
            rules="required"
            tag="div"
          >
            <md-field :class="{ error: errors.length }">
              <ValidationError :errors="errors" />
              <label>Тип предмета</label>
              <md-select
                v-model="selectedItem.itemTypeId"
                :disabled="isLoaderVisible"
                :md-dense="true"
                @focus="reset"
              >
                <md-option
                  v-for="itemType in itemTypes"
                  :key="`itemType${itemType.id}`"
                  :value="itemType.id"
                >
                  {{ itemType.name }}
                </md-option>
              </md-select>
            </md-field>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors, reset }"
            class="md-layout-item md-size-100 md-large-size-30"
            mode="passive"
            rules="required"
            tag="div"
          >
            <md-field :class="{ error: errors.length }">
              <ValidationError :errors="errors" />
              <label for="md-input-custom">Стоимость</label>
              <md-input
                v-model="selectedItem.price"
                :disabled="isLoaderVisible"
                type="number"
                @focus="reset"
              />
            </md-field>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors, reset }"
            class="md-layout-item md-size-100 md-large-size-30"
            mode="passive"
            rules="required"
            tag="div"
          >
            <md-field :class="{ error: errors.length }">
              <ValidationError :errors="errors" />
              <label for="md-input-custom">Количество</label>
              <md-input
                v-model="selectedItem.quantity"
                :disabled="isLoaderVisible"
                type="number"
                @focus="reset"
              />
            </md-field>
          </ValidationProvider>
        </ValidationObserver>

        <div class="buttons-wrap">
          <md-button
            class="md-raised md-success"
            :disabled="isLoaderVisible"
            @click="save"
          >
            Сохранить
          </md-button>

          <md-button class="md-raised md-danger" @click="close">
            Отменить
          </md-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedItem: null,
    };
  },

  computed: {
    ...mapState(["isLoaderVisible"]),
    ...mapState("items", ["itemTypes"]),
  },

  mounted() {
    this.selectedItem = JSON.parse(JSON.stringify(this.item));
  },

  methods: {
    ...mapActions("items", ["setShopItem"]),
    ...mapMutations({
      showLoader: "SET_SHOW_LOADER",
    }),

    clickOutside(e) {
      if (e.target.classList.contains("modal-backdrop")) {
        this.close();
      }
    },

    close(needUpdate = false) {
      this.$emit("close", needUpdate);
    },

    async save() {
      const isValid = await this.$refs.shopItemForm.validate();
      if (!isValid) {
        return;
      }

      this.showLoader(true);

      try {
        await this.setShopItem(this.selectedItem);
        this.close(true);
      } catch (error) {
        this.showLoader(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;

  .modal {
    position: relative;
    height: auto;
    padding: 20px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    background: #fff;
  }

  .btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 20px;
    color: #c4c4c4;
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.buttons-wrap {
  display: flex;

  & > * {
    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
}
</style>
